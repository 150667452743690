<template>
  <div>
    <Header/> 
    <b-row style="margin-top:5rem" class="p-1">
      <b-col md="8">
          <ValidationObserver ref="formCustomer" v-slot="{ invalid }"> 
            <b-form  @submit.prevent="handlerSubmit" >
              <!-- Left Form -->
              <b-card no-body>
                <b-card-header class="flex-column align-items-start">
                  <b-card-title>Complete your information</b-card-title>
                  <b-card-text class="text-muted mt-25">
                    Be sure to fill in the required fields *
                  </b-card-text>
                </b-card-header>
                <b-card-body>
                  <b-overlay :show="isProcessingCheckout" rounded> 
                    <b-row>
                      <b-col cols="12" md="6">
                        <b-form-group label="Email" label-for="email-customer">
                          <validation-provider
                            #default="{ errors }"
                            name="Email"
                            rules="required|email"
                          >
                            <b-form-input
                              v-model="customer.email"
                              :state="errors.length > 0 ? false:null"
                              id="email-customer"
                              placeholder="john@thefives.com.mx"
                              @input="checkIfEmailClientExist"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      
                      <b-col cols="12" md="6">
                        <b-form-group label="Name" label-for="name-customer">
                          <validation-provider
                            #default="{ errors }"
                            name="name"
                            rules="required"
                          >
                            <b-form-input
                              v-model="customer.name"
                              :state="errors.length > 0 ? false:null"
                              id="name-customer"
                              placeholder="John"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <b-col cols="12" md="6">
                        <b-form-group label="Surname" label-for="surname-customer">
                          <validation-provider
                            #default="{ errors }"
                            name="surname"
                            rules="required"
                          >
                            <b-form-input
                              v-model="customer.lastname"
                              :state="errors.length > 0 ? false:null"
                              id="surname-customer"
                              placeholder="Doe"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <b-col cols="12" md="6">
                        <b-form-group label="Phone" label-for="phone-customer">
                          <validation-provider
                            #default="{ errors }"
                            name="phone"
                            rules="required"
                          >
                            <b-form-input
                              oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                              maxlength="20"
                              @keypress="formatPhoneNumber"
                              type="text"
                              v-model="customer.phone"
                              :state="errors.length > 0 ? false:null"
                              id="phone-customer"
                              placeholder="983-54-53-30-8"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <b-col cols="12" md="6">
                        <b-form-group label="Hotel" label-for="hotel-customer">
                          <validation-provider
                            #default="{ errors }"
                            name="hotel"
                            rules="required"
                          >                  
                            <b-form-select
                                :state="errors.length > 0 ? false:null"                      
                                v-model="customer.hotel"
                                id="hotel-customer"
                              >
                                <option value="">Choose hotel</option>
                                <option
                                  v-for="hotel in hotels"
                                  :key="hotel.id"
                                  :value="hotel.id"
                                >
                                  {{ hotel.name }}
                                </option>
                              </b-form-select>
                            <small class="text-danger">{{ errors[0] }}</small>

                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <b-col cols="12" md="6">
                        <b-form-group label="Language" label-for="language-customer">
                          <validation-provider
                            #default="{ errors }"
                            name="language"
                            rules="required"
                          >                  
                            <b-form-select
                                :state="errors.length > 0 ? false:null"                      
                                v-model="customer.language"
                                id="language-customer"
                              >
                                <option value="">Choose a language</option>
                                <option
                                  v-for="language in languages"
                                  :key="language.id"
                                  :value="language.id"
                                >
                                  {{ language.name }}
                                </option>
                              </b-form-select>
                            <small class="text-danger">{{ errors[0] }}</small>

                          </validation-provider>
                        </b-form-group>
                      </b-col>                    
                    </b-row>
                  </b-overlay>    
                  <b-row class="mt-2">
                    <b-col cols="12">
                      <div class="float-right">
                        <b-button variant="danger"  v-if="orderCreated" @click="resetOrder" class="mr-1"> Reset order </b-button>
                        <b-button variant="primary" :disabled="invalid" v-if="orderCreated" @click="retryPayment"> Retry payment </b-button>

                        <b-button variant="primary" type="submit" :disabled="invalid || disabledBtnFinish" v-if="!orderCreated"> Finish </b-button>
                      </div>
                    </b-col>
                  </b-row>

                  <div v-if="notReadyItems.length">                         
                    <b>Products in the cart that are missing fields: </b>
                    <table class="table table-sm table-striped mt-1">
                        <thead class="thead-dark">
                          <tr>
                            <th scope="col">Item</th>                                                               
                            <th scope="col">Field</th>                                                  
                          </tr>
                        </thead>
                        <tbody>                                    
                          <tr v-for="(item, indice) in notReadyItems" :key="item.key">                                                                                           
                            <td scope="row"><b>{{indice + 1}}:</b>  {{ item.name }} </td> 
                            <td scope="row" style="color: red;">              
                              <span v-for="(valor, index) in item.fields"
                                  :key="valor.key"
                              > {{ valor.campo}} <span  v-if="index != item.fields.length - 1">,</span>
                              </span>  
                            </td>                                             
                          </tr>   
                        </tbody>
                    </table>                                           
                  </div>

                </b-card-body>
              </b-card> 

            </b-form>
          </ValidationObserver>          
      </b-col>
      <b-col md="4">
        <div class="customer-card">
            <b-card :title="titleCustomerCard">
              
              <b-list-group flush v-if="cart.length && !urlBankExist">
                <b-list-group-item v-for="(item) in cart" :key="item.uuid"><b>{{item.qty}}</b> - {{ item.name || item.detaildisplay }} - <b>$ {{item.saleprice}}</b> </b-list-group-item>
              </b-list-group>
                            
             <div v-if="urlBankExist" class="containerIframe">
              <iframe class="responsive-iframe" :src="orderCreated.payment.url" @load="getSrc"> </iframe>
             </div>
            </b-card>
          </div>
      </b-col>
    </b-row> 
    <!-- <Footer/>     -->
  </div>
</template>

<script>
/* eslint-disable global-require */
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { required, email } from '@validations'
import { market } from '@/modules/shop/mixins/market'
import Header from '@/modules/shop/components/home/Header'
import Footer from '@/modules/shop/components/home/Footer'

import { overWriteAxiosHeaders, toDecimal } from '@/helpers/helpers'
import { itemsCartNotReady, processItemsCart }from '@/helpers/shopHelper'

export default {
  mixins:[market],
  components: { 
    Header,
    Footer
  },   
  async created(){  
    if(this.queryParams){
      overWriteAxiosHeaders( this.queryParams ) //sobreescribo axios
    } 
    if( !this.hotels.length || !this.languages.length){
      const { hotels, languages } = await this.getInitialContentCheckout()
      this.setHotels(hotels)
      this.setLanguages(languages)
    }  
    document.title = `${this.user.name} ${this.user.lastname}`  
  },
  data() {
    return {      
      // validation rulesimport store from '@/store/index'
      required,
      email,
      //   email: ''          
      isProcessingCheckout : false,
    }
  },
  computed: {
    ...mapState('auth',['queryParams','user', 'isLogged']),                                
    ...mapState('start',['hotels','languages']),
    ...mapState('shop',['customer','cart', 'customerCurrency','breakdown','canalventa','puntoventa','orderCreated']) , 
    ...mapGetters('shop',['totalDiscountOnAllProducts','totalDiscountOrder']), 
    disabledBtnFinish(){
      return (!this.cart.length || itemsCartNotReady( this.cart ).length > 0 || this.isProcessingCheckout )
    },
    notReadyItems(){
      return itemsCartNotReady( this.cart )
    },
    urlBankExist(){
      let existUrl = false
      if( this.orderCreated ) {
        const { payment } = this.orderCreated
        if(payment){
          const { error, url } = payment
          existUrl = ( this.orderCreated && payment && !error && url != '' )
        }
      }
      return existUrl
    },
    titleCustomerCard(){
      const titleCustomer = `${this.customer.name} ${this.customer.lastname}`
      return  titleCustomer !== ' ' ? titleCustomer : 'Complete your information'
    }
  },
  methods: {   
    ...mapActions('shop',['getInitialContentCheckout','checkIfClientExists','saveCustomer','makeSale','checkOrderStatusPayment','retryPaymentOrder']),
    ...mapMutations('start',['setHotels','setLanguages']),
    ...mapMutations('shop',['setInfoClient','replaceCustomerData','setIdCustomer','removeIdCustomer','setOrderCreated','setStatusPaymentOrder','resetCartCustomerBreakdownOrder','setUrlBankPaymentOrder']),
    
    async handlerSubmit(){      
      this.isProcessingCheckout = true
      this.setDataCustomer() //muto el state de customer
      const { idCustomer } = this.customer      
      if(!idCustomer){
        const customerData = await this.saveCustomer(this.customer) //guardo el cliente, tomo su id u muto mi customer state        
        this.setIdCustomer(customerData.id) //tomo el id del cliente de customerData para ejcurtar la mutacion
      }
      const headersOrder = this.createHeadersOrder() //encabezados
      if(headersOrder.grossSale === '0.00'){ // Sí total de cuenta no pasa de 0
        headersOrder.paymentmethod = 1054 //Método de pago Not Applicable, como método de pago, no credit card 1
      }
      const detailsOrder = processItemsCart(this.cart, this.customer) // detalles de la orden
      const payload = { ...headersOrder, detail: [...detailsOrder] } //payload final, datos del cliente y productos del cart                 
      const orderCreated = await this.makeSale(payload)      
      this.setOrderCreated(orderCreated) //pongo en el state la orden generada      
      this.isProcessingCheckout = false 
      this.endSale(orderCreated) // si cuenta es 0 y no tarjeta de credito, debe ejecutarse este            
    }, 
    createHeadersOrder(){
      const { idCustomer, paymentreference, paymentmethod, paymentlink, hotel, customertype, customeragent, sihotCode, language } = this.customer
      const { currencyid } = this.customerCurrency      
      return {
        canalventa: this.canalventa, 
        cargomanual: false,
        currency: currencyid,
        customer: idCustomer,
        customeragent, customertype,
        discount: this.totalDiscountOrder,
        grossSale: toDecimal( parseFloat( this.totalDiscountOnAllProducts ) + parseFloat( this.breakdown.total ) ),
        hotel, hotelsihotcode: sihotCode,
        language, numreserva: '', orderStatus: 1, paymentlink, paymentmethod, paymentreference,
        puntoventa: this.puntoventa,
        saleUser: this.user.idUser,
        seccioncanal: this.user.sectionCanal.id,
        tax: "0.00",
        totalSale: this.breakdown.total,
      }
    },    
    setDataCustomer(){
      const { hotel } = this.customer
      this.customer.idUser = this.user.idUser
      this.customer.customertype = 8 // tipo de cliente es Directo Web
      this.customer.paymentmethod = 1 // credit card                
      this.customer.sihotCode = hotel !== '' ? this.hotels.find( h => h.id === hotel ).sihotCode : null
      this.customer.sellercanalventa = this.user.canalventa.id
      this.setInfoClient( this.customer )
    },
    async checkIfEmailClientExist() {
      const { email } = this.customer
      const mailRegex =/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i      
      if (mailRegex.test(email)) {        
        const cliente = await this.checkIfClientExists(email)
        const { id, email:emailClient, lastname, name, phone } = cliente        
        if(id){ //si viene un id de cliente          
          const confirmAction = await this.confirmAction()
          if(confirmAction){
            return this.replaceCustomerData({email: emailClient, lastname, name, phone, id }) //reemplazo datos del cliente en el customer y le pongo id
          }
          if(!confirmAction){
            return this.removeIdCustomer()
          }
        }
        if(!id){
          return this.removeIdCustomer()
        }
      }
    },
    
    async confirmAction(){      
      const { isConfirmed } =  await this.$swal.fire({
        title: 'Use data',
        html: '<strong style="color:red; font-size:18px">This mail is already in use, do you want to use the data of this mail? The values in the fields will be replaced if they already have information.</strong>',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        reverseButtons: true,
        customClass: { confirmButton: 'btn btn-success', cancelButton: 'btn btn-danger  mr-1' },
        buttonsStyling: false
      })
      return isConfirmed
    },
    async getSrc(){    
      if(this.orderCreated && this.orderCreated.order){
        const { transactionstatus } = await this.checkOrderStatusPayment(this.orderCreated.order)      
        this.setStatusPaymentOrder( {id: transactionstatus.id, status: transactionstatus.name } )
        const { statusPayment } = this.orderCreated
        setTimeout( () => {
          if( statusPayment.status == 'Approved' ){
            this.$swal.fire('Operation completed', `🎉🎉 The order ${this.orderCreated.order} has been completed. 🎉🎉`, 'success' )
            //limpio order created, vacío cart, reset datos del cliente, customerCurrency en nulo
            this.resetCartCustomerBreakdownOrderCurrency(null)
            //reseteo form de cliente
            this.$refs.formCustomer.reset() //reseteo form    
          } 
        }, 2000)      
      } 
    },
    async retryPayment(){
      this.isProcessingCheckout = true
      const payload = { order: this.orderCreated.order } 
      this.setOrderCreated(null) //pongo nulo state de orderCreated      
      const newDataPayment = await this.retryPaymentOrder(payload)
      newDataPayment.order = newDataPayment.invoice.order      
      this.setOrderCreated(newDataPayment) //pongo en el state lo nuevo     
      this.isProcessingCheckout = false              
    },
    async resetOrder(){
      const { isConfirmed } =  await this.$swal.fire({
        title: 'Clear all',
        html: '<strong style="color:red; font-size:18px">Are you sure to clear all cart information and data in the form?</strong>',
        icon: 'error',
        showCancelButton: true,
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        reverseButtons: true,
        customClass: { confirmButton: 'btn btn-success', cancelButton: 'btn btn-danger  mr-1' },
        buttonsStyling: false
      })
      if(isConfirmed){
        //limpio order created, vacío cart, reset datos del cliente, customerCurrency en nulo
        this.resetCartCustomerBreakdownOrder(null)
        this.$refs.formCustomer.reset() //reseteo form    
      }
    },
    endSale(payload){
      const {status, order, invoice, payment } = payload      
      if(status && order != '' && invoice != '' && !payment ){ //si hay un invoice, status true, hay una orden y payment es nulo
        this.$swal.fire('Operation completed', `🎉🎉 The order has been completed: ${invoice}. 🎉🎉`, 'success')
        this.resetCartCustomerBreakdownOrder(null)
        this.$refs.formCustomer.reset() //reseteo form  
      }
    }
  },
}
</script>

<style lang="scss">

@import '~@core/scss/base/pages/app-ecommerce.scss';
@import '@core/scss/vue/libs/vue-select.scss';


</style>
<style scoped>
iframe {
  overflow: hidden;
}

.containerIframe {
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-top: 619px;
}
.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
}
</style>
